.parent-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  /* Adjust the gap between flex containers */
}


.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
}

.contentElement {
  min-width: 320px;
  max-width: 90%;
  border-radius: 40px;
  box-sizing: border-box;
}

.contentImg{
  max-height: 95%;
  border-radius: 40px;
}

.featuresBloc{
  min-width: 320px;
  border-radius: 40px;
}

.strokeContainer {
  padding: 20px;
  border-radius: 20px;
  border: solid 1px #FFE5E5;
  background: white;
}

.descElement {
  max-width: 360px;
  border-radius: 10px;
}

.imgHght {
  max-height: 360px;
  height: 100%;
}

.slide {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slideElement {
  position: sticky;
  height: 600px;
  top: 0;
}

.nd {
  height: 490px;
  top: 110px;
}

.rd {
  height: 320px;
  top: 180px;
}

.sticky {
  position: sticky;
  top: 0;
}

.vSlide {
  flex-direction: column;
}

.slideL {
  flex-direction: column;
  align-items: flex-start;
}

.redBG {
  background-color: var(--color-red);
  color: white;
}

.situ {
  padding: 40px;
  border-radius: 40px;
  box-sizing: border-box;
}

.situInner {
  margin-top: 20px;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  background: white;
}

.situImg {
  min-width: 260px;
  max-width: 450px;
  width: 100%;
  border-radius: 10px;
}

.fEnd {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.unmargin {
  margin-top: -300px;
}

.flex-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.flex-wrapper>div {
  flex: 1;
  padding: 20px;
  border-radius: 20px;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--color-white);
  border: solid 1px #FFE5E5;
}

@media only screen and (max-width: 960px) {

  .flex-container {
    flex-direction: column;
  }
  
  .contentElement {
    max-width: 100%;
  }

  .situInner {
    flex-direction: column;
  }

  .situImg {
    max-width: none;
  }

  .flex-wrapper {
    flex-direction: column;
  }

  .flex-wrapper>div {
    min-height: auto;
  }

  .slide {
    height: auto;
  }

  .reverse {
    flex-direction: column-reverse;
  }

  .mSlide {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 768px) {


  .contentElement {
    border-radius: 20px;
  }

  .imgHght {
    min-width: 320px;
    max-height: none;
    width: 100%;
  }

  .descElement {
    max-width: none;
    width: 100%;
  }

  .situ {
    padding: 20px;
    border-radius: 20px;
    box-sizing: border-box;
  }

  .situInner {
    border-radius: 10px;
    gap: 40px;
  }

  .slideElement {
    position: unset;
    height: auto;
  }

  .rd {
    margin-top: 20px;
  }

}