.blogArticles{
    margin: 40px 0;
}

.blogOtherLinks{
    display: block;
    gap: 10px;
    padding: 20px;
    background: var(--color-white);
    border-radius: 20px;
    font-size: 1.17em;
    font-weight: bold;
    text-decoration: none;
    color: var(--color-grey);
    transition: 0.5s;
    margin-bottom: 20px;
}

.blogOtherLinks:hover {
    color: var(--color-black);
}

.blogLinksThread{
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;
}

.blogLinksThread a {
    text-decoration: none;
    color: var(--color-grey);
    transition: 0.5s;
}

.blogLinksThread a:hover{
    color: var(--color-black);
}

.blogCategory{
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 50px;
    background: var(--color-light-grey);
}

.blogMain{
    min-width: 280px;
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
}