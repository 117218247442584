.productsList {
    margin: 100px 0;
}

.listElement {
    padding: 20px;
    background: white;
    border: solid 1px #FFE5E5;
    border-radius: 20px;
    transition: 0.5s;
}

.listElement:hover {
    border-color: #474747;
}

.listFlex {
    display: flex;
    align-items: flex-start;
    column-gap: 20px;
}

.fillContainer {
    width: 100%;
}

.baseHFlex {
    display: flex;
    row-gap: 10px;
}


.price-tracker {
    position: relative;
    display: flex;
    align-items: center;
    width: fit-content;
    margin-bottom: 20px;
}

.tooltip {
    margin-left: 8px;
    /* Space between the icon and the text */
    cursor: pointer;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background: var(--color-black);
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    border-radius: 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    /* Position the tooltip above the icon */
    left: 25%;
    transform: translateX(-25%);
    opacity: 0;
    transition: opacity 0.5s;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.tooltip svg .tooltipCircle {
    transition: 0.5s;
}

.tooltip:hover svg .tooltipCircle {
    fill: var(--color-black);
}


@media only screen and (max-width: 768px) {

    .listFlex {
        flex-direction: column-reverse;
        row-gap: 20px;
    }

    .nonRev {
        flex-direction: row;
    }

}