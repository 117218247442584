@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

body {
  margin: 0;
  font-family: "Oswald", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-bg);
  padding: 0 20px;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-white: #FFFFFF;
  --color-light-grey: #E6E6E6;
  --color-grey: #474747;
  --color-dark-grey: #4D4D4D;
  --color-black: #000000;
  --color-red: #FF3D00;
  --color-hover-red: #ED4545;
  --color-light-red: #FFE5E5;
  --color-soft-red: #F6A2A2;
  --color-red-bg-text: #FFD8CC;
  --color-bg: #FFF9F7;
}

.mainLogo {
  height: 40px;
}

.pageWrapper {
  max-width: 1140px;
  min-width: 320px;
  padding: 0 20px;
  margin: 0 auto;
  box-sizing: border-box;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
  padding-inline-start: 0px;
}

.onbrdFlex {
  display: flex;
  width: calc(100vw - 40px);
  height: 100vh;
  padding: 20px 0;
  box-sizing: border-box;
}

.centeredFlex {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

.centeredV {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  column-gap: 10px;
}

.formElements {
  min-width: 300px !important;
  max-width: 420px !important;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.formL {
  margin: 40px auto 300px auto;
  max-width: 600px;
}

.formWrapper {
  max-width: 1140px;
  margin: 0 auto;
}

.formSlide {
  height: calc(100vh-92px);
}

.hoFlex {
  display: flex;
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 20px;
  border: solid 1px var(--color-light-red);
  background: var(--color-white);
}

.noMarginP {
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.pdngM {
  padding: 0 60px;
}

.productImg {
  max-width: 260px;
  width: 100%;
  border-radius: 10px;
}

.previewImg {
  align-self: flex-start;
}

.loadingImg {
  width: 64px;
  margin-bottom: 20px;
}

.relPos {
  position: relative;
}

.absPos {
  position: absolute;
}

.msgBanner {
  position: absolute;
  background: var(--color-red);
  color: var(--color-white);
  right: 0;
  top: 0;
  padding: 10px 0;
  width: 100%;
}

.hFlexFit {
  display: flex;
  align-items: center;
  gap: 20px;
}

.mainInput {
  flex-grow: 1;
  box-sizing: border-box;
  border: none;
  background: transparent;
  font-size: 24px;
  padding: 12px 24px;
  border: solid 1px var(--color-light-red);
  border-radius: 100px;
  background: var(--color-white);
  transition: 0.5s;
}

.mainTextarea {
  border-radius: 20px;
  min-height: 120px;
}

.mainInput:focus {
  outline: none;
  border-color: grey;
}

.inputSmall {
  font-size: 18px;
}

.formMessage {
  padding: 0 20px;
}

.clmnG {
  column-gap: 10px;
}

.marginB {
  margin-bottom: 20px;
}

.marginBB {
  margin-bottom: 40px;
}

.marginS {
  margin-bottom: 10px;
}

.margT {
  margin-top: 20px;
}

.marginT {
  margin-top: 100px;
}

.marginM {
  margin-top: 60px;
}

.pntr {
  cursor: pointer;
}

.btnBasic {
  padding: 0.625rem 1.25rem;
  text-decoration: none;
  background: var(--color-red);
  color: var(--color-white);
  transition: 0.5s;
  border: none;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.btnBasic:hover {
  background: var(--color-hover-red);
}

.btnBig {
  font-size: 24px;
  padding: 12px 24px;
}

.btnBlack {
  background: var(--color-black);
}

.btnBlack:hover {
  background: #3b3b3b;
}

.btnBasic:disabled {
  /* background: var(--color-light-grey);
  color: var(--color-white); */
  cursor: not-allowed;
}

h1 {
  font-size: 64px;
  line-height: 1.2;
  font-weight: 800;
}

h2 {
  font-size: 96px;
  line-height: 1;
  font-weight: 800;
}

h3 {
  font-size: 32px;
  line-height: 1.2;
  font-weight: 800;
}

p {
  font-size: 20px;
  line-height: 1.5;
  color: var(--color-dark-grey);
}

.bigP {
  font-size: 24px;
  color: var(--color-black);
}

.smallP {
  font-size: 16px;
}

.capText {
  text-transform: uppercase;
}

.redText {
  color: var(--color-red);
}

.whiteText {
  color: var(--color-white);
}

.redBG {
  background-color: var(--color-red);
  color: var(--color-white);
}

.lightText {
  color: var(--color-soft-red);
}

.medLightText {
  color: var(--color-red-bg-text);
}

.centeredText {
  text-align: center;
}

.loadingScreen {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.greyBtn {
  background: #ddd;
  color: var(--color-grey);
  transition: 0.5s;
}

.greyBtn:hover {
  background: var(--color-light-grey);
}

.cenButton {
  margin: 0 auto;
}

.mainFooter {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 80px;
  padding: 40px;
  min-width: 320px;
  margin: 120px auto 20px auto;
  border-radius: 40px;
  box-sizing: border-box;
}

.prodPreview {
  height: 100vh;
}

.footerLinks a {
  text-decoration: none;
  color: var(--color-white);
  transition: 0.5s;
}

.footerLinks a:visited {
  color: var(--color-white);
}

.footerLinks a:hover {
  color: var(--color-soft-red);
}

.faq-item {
  margin-bottom: 1rem;
  background: var(--color-red);
  border-radius: 20px;
  transition: all 0.5s;
  color: white;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 20px;
  border-radius: 10px;
  font-size: 24px;
  font-weight: bold;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  font-size: 18px;
}

.faq-answer.show {
  max-height: 200px;
  /* Adjust this value as needed */
  opacity: 1;
  padding: 20px;
  border-top: solid 1px var(--color-light-red);
}

.faq-answer.hide {
  max-height: 0;
  opacity: 0;
  padding: 0 1rem;
}

.faq-icon {
  margin-left: 1rem;
}

.faq-answer a {
  text-decoration: none;
  color: var(--color-white);
  transition: 0.5s;
}

.faq-answer a:visited {
  color: var(--color-white);
}

.faq-answer a:hover {
  color: var(--color-soft-red);
}

.footerLinksBlocks {
  min-width: 160px;
  width: 100%;
  padding: 10px 0;
  border-bottom: solid 1px var(--color-white);
}

.footerLogo {
  max-width: 400px;
  min-width: 260px;
  width: 100%;
}

.baseLink {
  text-decoration: none;
  font-size: 20px;
  line-height: 1.5;
  color: var(--color-dark-grey);
  transition: 0.5s;
}

.baseLink:hover {
  color: var(--color-red);
}

.redLink {
  text-decoration: none;
  color: var(--color-red);
  transition: 0.5s;
}

.redLink:hover {
  color: var(--color-soft-red);
}

.rAligned {
  text-align: right;
}

.cAligned {
  text-align: center;
}

.formLeft,
.formRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  box-sizing: border-box;
}

.formRight {
  background-color: var(--color-red);
  color: var(--color-white);
  align-items: flex-start;
  border-radius: 20px;
}

.fullWidth {
  width: 100%;
}

.pdng {
  padding-left: 20px;
}

.carouselContainer {
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.carouselContent {
  display: flex;
  animation: scroll 45s linear infinite;
  width: 200%;
}

.logoWrapper {
  flex: 0 0 auto;
  margin: 0 40px;
}

.descriptive-text {
  position: absolute;
  left: -9999px;
  top: -9999px;
  /* or visibility: hidden; */
}

.progressBarContainer {
  max-width: 516.75px;
  min-width: 280px;
  width: 100%;
}

.passwordField {
  position: relative;
}

.togglePasswordVisibility {
  position: absolute;
  right: 20px;
  top: 15.2px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #007BFF;
  font-size: 14px;
}

.PwInput {
  width: 100%;
  padding-right: 60px;
}

.trggrs {
  cursor: pointer;
  color: var(--color-red);
  transition: 0.5s;
}

.trggrs:hover {
  color: var(--color-hover-red);
  text-decoration: underline;
}

.footerTrggrs {
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-size: 16px;
  text-decoration: none;
  color: var(--color-white);
  transition: 0.5s;
}

.footerTrggrs:hover {
  color: var(--color-soft-red);
}

/* DeleteProduct.css */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  z-index: 1000;
}

.popup {
  min-width: 320px !important;
  max-width: 420px;
  width: 100% !important;
  background: var(--color-white);
  padding: 20px !important;
  border-radius: 20px;
}

.popup-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
}

.deleteElement svg path {
  transition: 0.5s;
}

.deleteElement:hover svg path {
  fill: var(--color-black);
}

.footerNdBlocks{
  display: flex;
  gap: 40px;
}


@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-200%);
  }
}


@media (max-width: 960px) {

  body {
    padding: 0 20px;
  }

  .formRight {
    display: none;
  }

  .formLeft {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: unset;
  }

  .prodPreview {
    height: auto;
  }

  .faq-container {
    padding: 0px;
  }

  .mainFooter {
    flex-direction: column-reverse;
    padding: 20px;
    border-radius: 20px;
  }

  .footerLinks {
    width: 100%;
  }

  .footerLinksBlocks {
    text-align: center;
  }

  .logoBlock p {
    text-align: center;
  }

  .logoBlock {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .footerLogo {
    max-width: unset;
  }

  .footerNdBlocks{
    width: 100%;
    flex-direction: column-reverse;
    gap: 40px;
  }
  
}

@media only screen and (max-width: 768px) {
  .hFlexFit {
    flex-direction: column;
    align-items: stretch;
  }

  .spbt {
    flex-direction: column;
    row-gap: 10px;
  }

  .endPos {
    justify-content: start;
  }

  .hoFlex {
    flex-direction: column-reverse;
  }

  .productImg {
    max-width: none;
  }

  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 48px;
  }


  .carouselContent {
    animation: scroll 10s linear infinite;
  }

  .logo-large {
    display: none;
  }

  .pdngM {
    padding: 0;
  }
}