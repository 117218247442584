.plans-grid {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.plan-element {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 280px;
    width: 100%;
    padding: 20px;
    border: solid 1px var(--color-light-red);
    border-radius: 20px;
    background-color: var(--color-white);
    box-sizing: border-box;
    transition: 0.5s;
}

.plan-element:hover {
    border-color: var(--color-black);
}

.plan-element h3 {
    margin-bottom: 10px;
    font-size: 1.5em;
    color: #333;
}

.plan-element .price {
    font-size: 2em;
    font-weight: bold;
    color: var(--color-red);
    margin-bottom: 15px;
}

.plan-element .savedPrice {
    text-decoration: line-through;
    color: #999;
    font-size: 0.9em;
    margin-right: 8px;
}

.plan-element .savings {
    color: grey;
}

.plan-element .advantages {
    flex-grow: 1;
    font-size: 0.9em;
    color: #666;
}

.plan-element .btnBasic {
    width: 100%;
}

/* Responsive styling */
@media (max-width: 768px) {
    .plans-grid {
        flex-direction: column;
    }

    .plan-element {
        max-width: none;
        width: 100%;
    }
}