.profileTrigger {
    position: relative;
    display: inline-block;
}

.profileCircle {
    height: 51.2px;
    width: 51.2px;
    border-radius: 50%;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 24px;
    transition: 0.5s;
}

.profileCircle:hover {
    background: #474747;
}

.dropdown {
    position: absolute;
    min-width: 143px;
    top: 60px;
    right: 0;
    background-color: white;
    border: solid 1px var(--color-light-red);
    padding: 20px;
    border-radius: 10px;
    z-index: 1000;
}

.fullEmail {
    margin: 0 0 10px 0;
}