  /* Individual section within the terms */
  .terms-section {
    margin-bottom: 20px;
  }

  /* Title of each section */
  .terms-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #444;
  }

  /* Content of each section */
  .terms-content {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    white-space: pre-wrap;
    /* Preserve formatting */
  }

  /* Style for paragraphs within the terms */
  .terms-section p {
    margin: 10px 0;
    padding-left: 10px;
  }

  /* Responsive Design */
  @media (max-width: 600px) {
    .terms-container h1 {
      font-size: 2rem;
    }

    .terms-title {
      font-size: 1.25rem;
    }

    .terms-content {
      font-size: 0.9rem;
    }
  }